<template>
  <span>
    <v-row dense>
      <v-col cols="12" lg="4">
        <v-card class="my-1">
          <v-card-title>
            <h5>Tipologia das Reclamações</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartTipologiaReclamacoesOptions 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" lg="4">
        <v-card class="my-1">
          <v-card-title>
            <h5>Tipologia das Faturas Sem Leitura</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartTipologiaFaturasSemLeituraOptions 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" lg="4">
        <v-card class="my-1">
          <v-card-title>
            <h5>Tipologia dos Acertos de Faturamento</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartTipologiaAcertosFaturamentoOptions 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </span>
</template>

<script>

export default {
  name: "DashboardFaturamentoVisaoAneelCardsTipologias",
  props: {
    dadosTipologiaReclamacoes: {
      type: Object,
      required: true,
    },
    dadosTipologiaFaturasSemLeitura: {
      type: Object,
      required: true,
    },
    dadosTipologiaAcertosFaturamento: {
      type: Object,
      required: true,
    },
    exportingOptions : {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    chartTipologiaReclamacoesOptions: {},
    chartTipologiaFaturasSemLeituraOptions: {},
    chartTipologiaAcertosFaturamentoOptions: {}
  }),
  mounted() {
    this.setChartOptions();
  },
  methods: {
    setChartOptions() {
      this.setChartTipologiaReclamacoesOptions();
      this.setChartTipologiaFaturasSemLeituraOptions();
      this.setChartTipologiaAcertosFaturamentoOptions();
    },
    getDefaultPieChartOptions() {
      return {
        chart: {
          type: 'pie',
          height: 300,
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              distance: 30,
              formatter: function() { 
                return `${this.point.name}: ${parseFloat(this.point.y).toLocaleString('pt-BR')}` 
                // return parseFloat(this.point.y).toLocaleString('pt-BR')
              }
            },
            showInLegend: true
          }
        },
        credits: { enabled: false },
        title: { text: null },
        subtitle: { text: null },
        legend: { enabled: false },
        lang: {
          noData: "Sem dados para exibição"
        },
        series: {
          name: "Valor",
          colorByPoint: true,
          innerSize: '75%',
          animation: false,
          data: [],
        },
        exporting:this.exportingOptions
      };
    },
    setChartTipologiaReclamacoesOptions() {
      let options = this.getDefaultPieChartOptions();

      options.series.data = [
        { name: "Tarifa" , y: this.dadosTipologiaReclamacoes.recl_tarifa},
        { name: "Fatura" , y: this.dadosTipologiaReclamacoes.recl_fatura},
        { name: "Variação de Consumo" , y: this.dadosTipologiaReclamacoes.variacao_consumo},
        { name: "Erro de Leitura" , y: this.dadosTipologiaReclamacoes.recl_erro_leitura},
        { name: "Custo de Disponibilidade" , y: this.dadosTipologiaReclamacoes.custo_disponibilidade},
        { name: "Cobrança indevida de acessória" , y: this.dadosTipologiaReclamacoes.cobranca_indevida}
      ];

      this.chartTipologiaReclamacoesOptions = options;
    },
    setChartTipologiaFaturasSemLeituraOptions() {
      let options = this.getDefaultPieChartOptions();

      options.series.data = [
        { name: "Impedimento de Acesso" , y: this.dadosTipologiaFaturasSemLeitura.com_009 },
        { name: "Situação de Emergência" , y: this.dadosTipologiaFaturasSemLeitura.com_010 },
        { name: "Leitura Plurimensal" , y: this.dadosTipologiaFaturasSemLeitura.com_011 },
        { name: "Aplicação do Art. 228" , y: this.dadosTipologiaFaturasSemLeitura.com_012 },
        { name: "Encerramento Contratual" , y: this.dadosTipologiaFaturasSemLeitura.com_013 },
        { name: "Ausência Temporária no Sistema" , y: this.dadosTipologiaFaturasSemLeitura.com_014 }
      ];

      this.chartTipologiaFaturasSemLeituraOptions = options;
    },
    setChartTipologiaAcertosFaturamentoOptions() {
      let options = this.getDefaultPieChartOptions();

      options.series.data = [
        { name: "Faturamento Incorreto" , y: this.dadosTipologiaAcertosFaturamento.com_017 },
        { name: "Devolução em Dobro" , y: this.dadosTipologiaAcertosFaturamento.com_018 },
        { name: "Devolução Simples (Consumidor)" , y: this.dadosTipologiaAcertosFaturamento.com_019 },
        { name: "Devolução Simples (Terceiro)" , y: this.dadosTipologiaAcertosFaturamento.com_020 },
        { name: "Impedimento de Acesso" , y: this.dadosTipologiaAcertosFaturamento.com_021 },
        { name: "Situação de Emergência" , y: this.dadosTipologiaAcertosFaturamento.com_022 },
        { name: "Sem Leitura, Faturamento Médio" , y: this.dadosTipologiaAcertosFaturamento.com_023 }
      ];

      this.chartTipologiaAcertosFaturamentoOptions = options;
    },
  },
  watch: {
    dadosTipologiaReclamacoes() {
      this.setChartOptions();
    },
    dadosTipologiaFaturasSemLeitura() {
      this.setChartOptions();
    },
    dadosTipologiaAcertosFaturamento() {
      this.setChartOptions();
    },
  }
}

</script>

<style>

</style>